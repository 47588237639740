$rem: 75px;
@function px2rem($px) {
  @return $px / $rem * 1rem;
}

@mixin backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: px2rem(750px);
  overflow: hidden;
  background-color: #fff;
  .hongbao {
    position: fixed;
    right: px2rem(30px);
    bottom: px2rem(30px);
    width: px2rem(100px);
    height: px2rem(100px);
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s backwards 1;
  }
  .hongbao-show {
    transform: translateX(0) rotate(0deg);
  }
  .hongbao-hide {
    transform: translateX(px2rem(100px)) rotate(-40deg);
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .bg-svg {
    position: absolute;
    right: 0;
    left: 0;
    // height: px2rem(300px);
    width: px2rem(750px);
    // z-index: -1;
  }
  .close {
    position: absolute;
    top: px2rem(95px);
    right: px2rem(30px);
  }
  .swiper-container {
    width: px2rem(660px);
    height: px2rem(329px);
    margin-top: px2rem(15px);
    margin-right: auto;
    margin-bottom: px2rem(13px);
    margin-left: auto;
    border-radius: px2rem(20px);
    .swiper-slide {
      /* Center slide text vertically */
      display: flex;
      align-items: center;
      justify-content: center;
      width: px2rem(660px);
      height: px2rem(329px);
      font-size: 18px;
      text-align: center;
      background: #fff;
      border-radius: px2rem(20px);

      @include backgroundImage;
    }
  }
  .mock-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .loading-spinner {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    animation: loading-spinner-spin 1s linear infinite;
  }

  @keyframes loading-spinner-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .goto-bottom-icon {
    position: fixed;
    top: px2rem(1140px);
    left: 50%;
    width: px2rem(64px);
    height: px2rem(12px);
    background-image: url(./assets//arrow.png);
    transform: translate(-50%, 0);

    @include backgroundImage;
  }
  @keyframes slideUp {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @keyframes stepShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes handAnim {
    0% {
      transform: translate(px2rem(10px), px2rem(10px));
    }
    100% {
      transform: translate(px2rem(20px), px2rem(20px));
    }
  }
  .product-container {
    padding: px2rem(48px) px2rem(31px) px2rem(71px) px2rem(28px);
    .product-item-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .single-item {
      position: relative;
      .coupon-item-wrapper {
        position: absolute;
        right: px2rem(-10px);
        bottom: px2rem(43px);
        .coupon-item {
          width: px2rem(138px);
          height: px2rem(132px);
          @include backgroundImage;
        }

        .hand {
          position: absolute;
          right: px2rem(80px);
          bottom: px2rem(103px);
          width: px2rem(64px);
          height: px2rem(55px);
          animation: handAnim .6s linear infinite alternate;
          transition: opacity .6s;
          opacity: 0;
          &.show {
            opacity: 1;
          }
          &.hide {
            opacity: 0;
          }
        }
      }
    }
    .product-title-img {
      align-self: flex-start;
      width: px2rem(290px);
      height: px2rem(55px);
      margin-left: px2rem(-10px);
      // margin: px2rem(12px) auto;
    }

    .product-item {
      width: px2rem(660px);
      height: px2rem(330px);
      margin: px2rem(32px) auto;
    }
  }
}
