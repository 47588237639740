body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 10rem;
  overflow: hidden;
  background-color: #fff; }
  .App .hongbao {
    position: fixed;
    right: 0.4rem;
    bottom: 0.4rem;
    width: 1.33333rem;
    height: 1.33333rem;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s backwards 1;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s backwards 1; }
  .App .hongbao-show {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg); }
  .App .hongbao-hide {
    -webkit-transform: translateX(1.33333rem) rotate(-40deg);
            transform: translateX(1.33333rem) rotate(-40deg); }

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }
  .App .bg-svg {
    position: absolute;
    right: 0;
    left: 0;
    width: 10rem; }
  .App .close {
    position: absolute;
    top: 1.26667rem;
    right: 0.4rem; }
  .App .swiper-container {
    width: 8.8rem;
    height: 4.38667rem;
    margin-top: 0.2rem;
    margin-right: auto;
    margin-bottom: 0.17333rem;
    margin-left: auto;
    border-radius: 0.26667rem; }
    .App .swiper-container .swiper-slide {
      /* Center slide text vertically */
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8.8rem;
      height: 4.38667rem;
      font-size: 18px;
      text-align: center;
      background: #fff;
      border-radius: 0.26667rem;
      background-repeat: no-repeat;
      background-size: cover; }
  .App .mock-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3); }
  .App .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    -webkit-animation: loading-spinner-spin 1s linear infinite;
            animation: loading-spinner-spin 1s linear infinite; }

@-webkit-keyframes loading-spinner-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-spinner-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .App .goto-bottom-icon {
    position: fixed;
    top: 15.2rem;
    left: 50%;
    width: 0.85333rem;
    height: 0.16rem;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAMCAMAAAANrf7QAAAANlBMVEUAAAC/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+ZgUeCAAAAEXRSTlMA6ZE39dp/yaRuXEklFgu4tH6I4IUAAABrSURBVCjPtZJHDsAgDAQJvZf/fzYnsIRBqxwyR9tyGVko+YzJI5UAHMqT1ytkbBZXsjVjon1a8RrbWLhQxIESHNW0WO9Z3fdsjV0f+uPdwHXIzBe3fNS2EICL5LIwJJJkIbhIJAu/GMn6gxeMLQ3tIWcwCwAAAABJRU5ErkJggg==);
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    background-repeat: no-repeat;
    background-size: cover; }

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes slideUp {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@-webkit-keyframes stepShow {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes stepShow {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes handAnim {
  0% {
    -webkit-transform: translate(0.13333rem, 0.13333rem);
            transform: translate(0.13333rem, 0.13333rem); }
  100% {
    -webkit-transform: translate(0.26667rem, 0.26667rem);
            transform: translate(0.26667rem, 0.26667rem); } }

@keyframes handAnim {
  0% {
    -webkit-transform: translate(0.13333rem, 0.13333rem);
            transform: translate(0.13333rem, 0.13333rem); }
  100% {
    -webkit-transform: translate(0.26667rem, 0.26667rem);
            transform: translate(0.26667rem, 0.26667rem); } }
  .App .product-container {
    padding: 0.64rem 0.41333rem 0.94667rem 0.37333rem; }
    .App .product-container .product-item-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .App .product-container .single-item {
      position: relative; }
      .App .product-container .single-item .coupon-item-wrapper {
        position: absolute;
        right: -0.13333rem;
        bottom: 0.57333rem; }
        .App .product-container .single-item .coupon-item-wrapper .coupon-item {
          width: 1.84rem;
          height: 1.76rem;
          background-repeat: no-repeat;
          background-size: cover; }
        .App .product-container .single-item .coupon-item-wrapper .hand {
          position: absolute;
          right: 1.06667rem;
          bottom: 1.37333rem;
          width: 0.85333rem;
          height: 0.73333rem;
          -webkit-animation: handAnim .6s linear infinite alternate;
                  animation: handAnim .6s linear infinite alternate;
          transition: opacity .6s;
          opacity: 0; }
          .App .product-container .single-item .coupon-item-wrapper .hand.show {
            opacity: 1; }
          .App .product-container .single-item .coupon-item-wrapper .hand.hide {
            opacity: 0; }
    .App .product-container .product-title-img {
      align-self: flex-start;
      width: 3.86667rem;
      height: 0.73333rem;
      margin-left: -0.13333rem; }
    .App .product-container .product-item {
      width: 8.8rem;
      height: 4.4rem;
      margin: 0.42667rem auto; }

a {
  text-decoration: none;
}

.footer .links,
.unshare .ui-dialog-content {
  text-align: center;
}

.footerWrapper {
  /* background: #2c323b; */
}

.footer {
  padding-bottom: 50px;
}

.footer .links {
  position: relative;
  zoom: 1;
  border-bottom: 1px solid #3d424a;
  padding: 15px 0;
  font-size: 12px;
  color: #b1b3b9;
}

.footer .links a {
  color: #b1b3b9;
  padding: 10px;
}

.footer .copyright {
  width: 1000px;
  margin: 40px auto 0;
}

.footer .copyright p {
  line-height: 30px;
  text-align: center;
  color: #b1b3b9;
}

.footer .copyright a.office-verification {
  margin-left: 5px;
  padding: 5px 10px;
  background: url(//www.kugou.com/common/images/logo_down.png) no-repeat;
  background-size: 20px 20px;
}

.footer .copyright a.office-verification img {
  width: 20px;
  height: 20px;
  vertical-align: top;
}

.footer .copyright .footerIcon,
.footer .copyright .footerIcon a {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
}

.footer .copyright .footerIcon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: -2px;
  *margin-top: 8px;
  background: url(//www.kugou.com/common/images/footer_icon.png) no-repeat 0 0;
  *background-position: 0 0;
}

.footer .copyright .footerIcon a {
  padding: 0;
}

.footer .copyright a {
  color: #999;
}
