a {
  text-decoration: none;
}

.footer .links,
.unshare .ui-dialog-content {
  text-align: center;
}

.footerWrapper {
  /* background: #2c323b; */
}

.footer {
  padding-bottom: 50px;
}

.footer .links {
  position: relative;
  zoom: 1;
  border-bottom: 1px solid #3d424a;
  padding: 15px 0;
  font-size: 12px;
  color: #b1b3b9;
}

.footer .links a {
  color: #b1b3b9;
  padding: 10px;
}

.footer .copyright {
  width: 1000px;
  margin: 40px auto 0;
}

.footer .copyright p {
  line-height: 30px;
  text-align: center;
  color: #b1b3b9;
}

.footer .copyright a.office-verification {
  margin-left: 5px;
  padding: 5px 10px;
  background: url(//www.kugou.com/common/images/logo_down.png) no-repeat;
  background-size: 20px 20px;
}

.footer .copyright a.office-verification img {
  width: 20px;
  height: 20px;
  vertical-align: top;
}

.footer .copyright .footerIcon,
.footer .copyright .footerIcon a {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
}

.footer .copyright .footerIcon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: -2px;
  *margin-top: 8px;
  background: url(//www.kugou.com/common/images/footer_icon.png) no-repeat 0 0;
  *background-position: 0 0;
}

.footer .copyright .footerIcon a {
  padding: 0;
}

.footer .copyright a {
  color: #999;
}